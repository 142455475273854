import React from 'react'
import DataCenterImg from '../../images/printer-3.png'
import COLORS from '../../resources/colors'
import './LendersHero.css'

export default class Hero extends React.Component {
  constructor(props) {
    super(props)

    // array with texts to type in typewriter
    this.dataText = [
      'A different kind of loan servicing.',
      'A different kind of collection partner.',
    ]
  }

  componentDidMount() {
    // start the text animation
    //this.startTextAnimation(0);
  }

  // type one text in the typwriter
  // keeps calling itself until the text is finished
  typeWriter(text, i, fnCallback) {
    // chekc if text isn't finished yet
    if (i < text.length) {
      // add next character to h1
      document.querySelector('h1').innerHTML =
        text.substring(0, i + 1) + '<span aria-hidden="true"></span>'

      setTimeout(() => {
        this.typeWriter(text, i + 1, fnCallback)
      }, 100)
    }
    // text finished, call callback if there is a callback function
    else if (typeof fnCallback == 'function') {
      // call callback after timeout
      setTimeout(fnCallback, 700)
    }
  }
  // start a typewriter animation for a text in the dataText array
  startTextAnimation(i) {
    if (typeof this.dataText[i] == 'undefined') {
      setTimeout(() => {
        this.startTextAnimation(0)
      }, 5000)
    } else {
      // check if dataText[i] exists
      if (i < this.dataText[i].length) {
        // text exists! start typewriter animation
        this.typeWriter(this.dataText[i], 0, () => {
          // after callback (and whole text has been animated), start next text
          this.startTextAnimation(i + 1)
        })
      }
    }
  }

  render() {
    return (
      <div className="LoanPageHero Root" id="loanhero">
        <div className="TopContent">
          <div className="TopContentLeftContent">
            {/* <h1 className="Title">A different kind of loan servicing and collection partner</h1> */}
            {/* <p className ="Paragraph">We are different. A full service platform powered by humans and automation, we handle everything from origination to collection.</p> */}
            <h1 className="Title">
              A different kind of debt repayment and optimization partner
            </h1>
            <p className="Paragraph">
              We are different. We offer a full service automation platform
              powered by machine learning and humans, we use data to maximize
              your returns and collections.
            </p>
            <button className="Button" onClick={this.props.displayForm}>
              Learn More
            </button>
          </div>
          <div className="TopContentRightContent">
            <img src={DataCenterImg}></img>
          </div>
        </div>
        <div className="BottomContent">
          <div className="TopHalf"></div>
          <div className="BottomHalf"></div>
          <div className="DownArrow">
            <img src={require('../../images/down-arrow.png')}></img>
          </div>
        </div>
        <style jsx="true">{`
                        #loanhero .Root { 
                            font-family: "Helvetica Neue", "Arial";
                            width: 100vw;
                            height: auto;
                            background-color: transparent;
                        }

                        #loanhero .TopContent { 
                            background-color: ${COLORS.LightPurpleBlue};
                            padding: 7.295vw 5vw 5vw 5vw;
                            display: grid;
                        }

                        #loanhero .BottomContent { 
                            display: grid;
                            grid-template-rows: 5.47vw 5.47vw; 
                            padding: 0px 0px 4vw 0px; 
                        }

                        #loanhero .Title { 
                            font-weight: 700;
                            font-size: 3.5vw; 
                            line-height: 4vw;
            
                            width: 60vw; 
                            margin: 0px;
                            color: white;
                            padding: 1vw 0px 0px 0px;  
                        }

                        #loanhero .TopContentLeftContent { 
                            grid-row: 1;
                            grid-column: 1;
                            z-index: 1;
                        }
                        
                        #loanhero .TopContentRightContent { 
                            grid-row: 1;
                            grid-column: 1;
                            grid-template-columns: 40% 60%;
                            display: grid;
                            align-items: center;
                            z-index: 0;
                        }
                        
                        #loanhero .TopContentRightContent img { 
                            grid-row: 1;
                            grid-column: 2;
                            justify-self: end;
                            height: 100%;
                            width: auto;
                            object-fit: contain;
                            display: block;
                            height: 17vw;
                        }

                        #loanhero .Paragraph { 
                            font-weight: 300;
                            font-size: 1.5vw;
                            line-height: 2.18vw;
                            width: 40vw;
                            margin: 0px;
                            padding: 3.645vw 0px;
                            color: white;
                        }

                        #loanhero .Button { 
                            font-size: 0.95vw;
                            font-weight: bold;
                            background-color: ${COLORS.funCyan};
                            color: ${COLORS.myClerkieBlack}; 
                            padding: 1.19vw 2.97vw;
                            border: 0px solid rgba(255, 255, 255, 1.0);
                            border-radius: 2.38vw;
                            cursor: pointer; 
                        }

                        #loanhero button:focus { 
                            outline: none;
                        }

                        #loanhero .TopHalf { 
                            grid-row: 1;
                            background-color: ${COLORS.LightPurpleBlue};
                            grid-column: 1;
                        }

                        #loanhero .BottomHalf { 
                            grid-row: 2;
                            background-color: transparent;
                            grid-column: 1;
                        }

                        #loanhero .DownArrow { 
                            width: 7vw;
                            height: 7vw;
                            border-radius: 3.5vw;
                            background-color:  ${COLORS.funCyan};
                            display: grid;
                            margin: auto;
                            grid-row: 1 / span 2;
                            grid-column: 1;
                            align-items: center;
                        }

                        #loanhero .DownArrow img { 
                            width: 4vw;
                            height: 4vw;
                            object-fit: contain;
                            display: block;
                            justify-self: center;
                            animation-duration: 1.5s;
                            animation-timing-function: ease-in-out;
                            animation-iteration-count: infinite;
                            animation-name: throb;
                        }

                        @keyframes throb {
                            0% {
                            transform: translate(0px, -0.3vw);
                            }
                            50% {
                                transform: translate(0px, 0.5vw);
                            }
                            100% {
                                transform: translate(0px, -0.3vw);
                            }
                        }


                        #loanhero .DownArrowText { 
                            color: ${COLORS.myClerkieBlack};
                            font-weight: 400;
                            font-size: 1.19vw;
                            padding: 0px;
                            margin: 0px auto 0px auto;
                            transition: 1s;
                            opacity; 0;
                        }

                        #loanhero .DownArrowText.is-visible { 
                            color: ${COLORS.myClerkieBlack};
                            font-weight: 800;
                            font-size: 1.19vw;
                            padding: 0px;
                            margin: 0px auto 0px auto;
                            transition: 1s;
                            opacity; 100;
                        }



                        @media (max-width: 640px) { 
                            #loanhero .Root { 
                                font-family: "Helvetica Neue", "Arial";
                                width: 100vw;
                                height: auto;
                                background-color: transparent;
                            }

                            #loanhero .TopContent { 
                                background-color: ${COLORS.LightPurpleBlue};
                                padding: 14.59vw 10vw 10vw 10vw;
                                display: grid;
                            }

                            #loanhero .BottomContent { 
                                display: grid;
                                grid-template-rows: 10.94vw 10.94vw; 
                                padding: 0px 0px 0vw 0px; 
                            }

                            #loanhero .Title { 
                                font-weight: 700;
                                font-size: 12.16vw;
                                line-height: 14vw;

                                width: 80vw; 
                                margin: 0px;
                                color: white;
                                padding: 10vw 0px 0px 0px;  
                            }

                            #loanhero .TopContentLeftContent { 
                                grid-row: 1;
                                grid-column: 1;
                                z-index: 1;
                            }
                        
                            #loanhero .TopContentRightContent { 
                                display: none;   
                            }
                        
                            #loanhero .TopContentRightContent img { 
                                grid-row: 1;
                                grid-column: 2;
                                justify-self: start;
                                height: 100%;
                                width: auto;
                                object-fit: contain;
                                display: block;
                                height: 26vw;
                            }

                            #loanhero .Paragraph { 
                                font-weight: 300;
                                font-size: 4.86vw;
                                width: 60vw;
                                line-height: 7.29vw;
                                margin: 0px;
                                padding: 7.29vw 0px;
                                color: white;
                            }

                            #loanhero .Button { 
                                font-size: 3.89vw;
                                font-weight: bold;
                                background-color: ${COLORS.funCyan};
                                color: ${COLORS.myClerkieBlack}; 
                                padding: 6.08vw 24.3vw;
                                border: 0px solid rgba(255, 255, 255, 1.0);
                                border-radius: 9.73vw;
                                cursor: pointer; 
                            }

                            #loanhero .TopHalf { 
                                grid-row: 1;
                                background-color: ${COLORS.LightPurpleBlue};
                                grid-column: 1;
                            }

                            #loanhero .BottomHalf { 
                                grid-row: 2;
                                background-color: transparent;
                                grid-column: 1;
                            }

                            #loanhero .DownArrow { 
                                width: 21.89vw;
                                height: 21.89vw;
                                border-radius: 10.9vw;
                                background-color:  ${COLORS.funCyan};
                                display: grid;
                                margin: auto;
                                grid-row: 1 / span 2;
                                grid-column: 1;
                                align-items: center;
                            }

                            #loanhero .DownArrow img { 
                                width: 11vw;
                                height: 11vw;
                                object-fit: contain;
                                display: block;
                                justify-self: center;
                                animation-duration: 1.5s;
                                animation-timing-function: ease-in-out;
                                animation-iteration-count: infinite;
                                animation-name: throb;
                            }

                            @keyframes throb {
                                0% {
                                transform: translate(0px, -0.6vw);
                                }
                                50% {
                                    transform: translate(0px, 0.8vw);
                                }
                                100% {
                                    transform: translate(0px, -0.6vw);
                                }
                            }

                            #loanhero .DownArrowText { 
                                color: ${COLORS.myClerkieBlack};
                                font-weight: 800;
                                font-size: 3.89vw;
                                margin: auto;
                            }
                        }
                `}</style>
      </div>
    )
  }
}
