import React, { useState, useEffect } from 'react';
import VisibilitySensor from "react-visibility-sensor"
import COLORS from "../../resources/colors"

export default class Content extends React.Component { 
    
    constructor(props) { 
        super(props)

        this.id = props.id ? props.id : "loancontent"

        this.myRef = React.createRef();

        this.state = { 
            height: 0,
            visible: false
        }    
    }

    componentDidMount() { 

        var height = 0
        
        if (this.myRef.current != null) { 
            height = this.myRef.current.clientHeight ? this.myRef.current.clientHeight : 0
        }

        this.setState({
            height: height
        })
    }

    visibilityChanged = (visible) => { 
        this.setState(
            {
                visible: visible
            }
        )
    } 

    render() { 
        return(
            <div ref={this.myRef} id="loansecurity">

                <VisibilitySensor key="loansecurity" active={this.state.visible == false} partialVisibility={true} minTopValue={this.state.height/2} onChange={this.visibilityChanged} >
                    <div className="Content">
                        <div className={this.state.visible ? "TextContent Visible" : "TextContent"}>
                            <h1 className ="Title">We are serious about security</h1>
                            <p className="Paragraph"><span style={{fontWeight: "800"}}>Clerkie</span> encrypts all data to protect your borrowers’ personal information. We use role based access controls and maintain an audit log for every access event and change made in the system. We also perform real time security monitoring.</p>
                        </div>
                        
                    </div>
                </VisibilitySensor>
                         
                <style jsx="true">{`
    
                    #loansecurity .Content { 
                        background-color: ${COLORS.LightPurpleBlue};
                        padding: 10vw 5vw;
                    }

                    #loansecurity .TextContent { 
                        visibility: hidden;
                    }

                    #loansecurity .TextContent.Visible { 
                        animation-duration: 0.75s;
                        animation-name: fadeUp; 
                        animation-fill-mode: both;
                        animation-delay: 0.0s; 
                    }

                    @keyframes fadeUp {
                        0% {
                          transform: translate(0, 100px);
                          visibility: hidden;
                          opacity: 0;
                        }
                        100% {
                          transform: translate(0, 0);
                          visibility: visible;
                          opacity: 1;
                        }
                      }
    
                    #loansecurity .Title { 
                        font-weight: 700;
                        font-size: 3.3vw;
                        width: 70vw; 
                        color: ${COLORS.white};
                        text-align: center;
                        padding: 0px 0px 0px 0px;
                        margin: auto;
                    }
    
                    #loansecurity .Paragraph { 
                        font-weight: 300;
                        font-size: 1.5vw;
                        line-height: 2.25vw;
                        width: 55vw;
                        margin: auto;
                        padding: 3.68vw 0px 0px 0px;
                        color: ${COLORS.white};
                        text-align: center;
                    }

                    @media (max-width: 640px) { 
                        #loansecurity .Content { 
                            background-color: ${COLORS.LightPurpleBlue};
                            padding: 20vw 10vw 20vw 10vw;
                        }
    
                        #loansecurity .Title { 
                            font-weight: 700;
                            font-size: 9.78vw;
                            width: 100%; 
                            margin: 0px;
                            color: ${COLORS.white};
                            text-align: center;
                            padding: 0px 0px 0px 0px;
                        }
    
                        #loansecurity .Paragraph { 
                            font-weight: 300;
                            font-size: 4.86vw;
                            width: 100%;
                            line-height: 7.29vw;
                            margin: 0px;
                            padding: 7.29vw 0px 0px 0px;
                            color: ${COLORS.white};
                            text-align: center;
                        }
                    }
      
                `}</style>
            </div>
        )
    }
    
}   