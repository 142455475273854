import React, { useState, useEffect } from 'react';
import COLORS from "../../resources/colors"

export default function FormLink(props) { 

    function buttonPressed() { 
        props.displayForm();
    }

    return(
        <div className="Root" id="loanformlink">
            
                <div className="Content">
                    <h1 className ="Subtitle">Ready to get started?</h1>
                    <h1 className ="Subtitle2">Get in touch and give your borrowers a simpler way to manage their payments</h1>
                    <div className="ButtonContainer">
                        <button className="Button" onClick={buttonPressed}>Get Started</button>
                    </div>
                </div>
            
            <style jsx="true">{`

                #loanformlink .Root { 
                    font-family: "Helvetica Neue", "Arial";
                    width: 100%;
                    height: auto;
                    background-color: transparent;
                }

                #loanformlink .Content { 
                    background-color: ${COLORS.black};
                    padding: 7vw 2.5vw;
                }

                #loanformlink .Title { 
                    font-weight: 600;
                    font-size: 3.3vw;
                    width: 60vw; 
                    margin: auto;
                    color: ${COLORS.white};
                    text-align: center;
                    padding: 0px 0px 3vw 0px;
                }

                #loanformlink .Subtitle {
                    font-weight: 300;
                    color: ${COLORS.LightPurpleBlue};
                    display: block;
                    text-align: center;
                    width: 50vw; 
                    margin: auto;
                    padding: 0px 0px 1.5vw 0px;
                }

                #loanformlink .Subtitle2 {
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 45px;
                    color: ${COLORS.white};
                    margin: .75em 0;
                    text-align: center;
                    width: 50vw; 
                    margin: auto;
                    padding: 0px 0px 3vw 0px;
                    opacity: 0.4;
                }

                #loanformlink .ButtonContainer { 
                    display: grid;
                }

                #loanformlink .Button { 
                    font-size: 0.95vw;
                    font-weight: bold;
                    background-color: ${COLORS.LightPurpleBlue};
                    color: ${COLORS.white}; 
                    padding: 1.19vw 2.97vw;
                    border: 0px solid rgba(255, 255, 255, 1.0);
                    border-radius: 2.38vw;
                    cursor: pointer; 
                    justify-self: center;
                }

                #loanformlink button:focus { 
                    outline: none;
                }


                @media (max-width: 640px) { 
                    #loanformlink .Root { 
                        font-family: "Helvetica Neue", "Arial";
                        width: 100%;
                        height: auto;
                        background-color: transparent;
                    }
    
                    #loanformlink .Content { 
                        padding: 30vw 5vw 30vw 5vw;
                    }
    

                    #loanformlink .Subtitle {
                        font-size: 9.78vw;
                        font-weight: 300;
                        display: block;
                        text-align: center;
                        width: 75vw; 
                        margin: auto;
                        padding: 0px 0px 1.5vw 0px;
                    }
    
                    #loanformlink .Subtitle2 {
                        font-weight: 400;
                        font-size: 6vw;
                        line-height: 8.5vw;
                        margin: 0;
                        text-align: center;
                        width: 75vw; 
                        margin: auto;
                        padding: 7vw 0vw 8vw 0vw;
                    }

                    #loanformlink .ButtonContainer { 
                        display: grid;
                    }
    
                    #loanformlink .Button { 
                        font-size: 3.89vw;
                        font-weight: bold; 
                        padding: 6.08vw 24.3vw;
                        border: 0px solid rgba(255, 255, 255, 1.0);
                        border-radius: 9.73vw;
                        cursor: pointer; 
                        justify-self: center;
                    }
                }

            `}</style>
        </div>
    )
}   