import React, { useState, useEffect } from 'react'
import COLORS from '../../resources/colors'

export default function Welcome() {
  return (
    <div id="loanwelcome">
      <div className="TopContent">
        <h1 className="Title">Welcome to Clerkie</h1>
        <div className="Line"></div>
        {/* <p className ="Paragraph">Clerkie Servicing is a powerful and flexible platform where lenders, big and small, can easily manage their loan portfolio. Our fully-integrated platform decreases the time, cost, and headache lenders experience when servicing their loans. Let's work together!</p> */}
        <p className="Paragraph">
          A powerful and flexible platform where lenders, big and small, can
          easily manage and optimize their loan portfolio. Our fully-integrated
          platform decreases the time, cost, and headache lenders experience
          when recovering their loans. Let's work together!
        </p>
      </div>
      <style jsx="true">{`
        #loanwelcome .TopContent {
          background-color: ${COLORS.black};
          padding: 0vw 0vw 7.5vw 0vw;
        }

        #loanwelcome .Title {
          font-weight: 700;
          font-size: 3.3vw;
          width: 100%;
          margin: 0px;
          color: ${COLORS.white};
          text-align: center;
          padding: 0px;
        }

        #loanwelcome .Line {
          width: 10vw;
          background-color: ${COLORS.LightPurpleBlue};
          height: 0.75vw;
          border-radius: 0.75vw;
          margin: 2.5vw auto 0px auto;
        }

        #loanwelcome .Paragraph {
          grid-column: 1;
          font-weight: 400;
          font-size: 1.5vw;
          line-height: 2.25vw;
          width: 50vw;
          margin: 2.5vw auto 0px auto;
          padding: 0px;
          color: ${COLORS.white};
          text-align: center;
          opacity: 0.8;
        }

        @media (max-width: 640px) {
          #loanwelcome .TopContent {
            padding: 17.5vw 10vw 15vw 10vw;
          }

          #loanwelcome .Title {
            font-weight: 700;
            font-size: 9.7vw;
            width: 100%;
            margin: 0px;
            text-align: center;
            padding: 0px;
          }

          #loanwelcome .Line {
            width: 20vw;
            height: 1.46vw;
            border-radius: 1.46vw;
            margin: 4.86vw auto 0px auto;
          }

          #loanwelcome .ParagraphContainer {
            width: 100%;
          }

          #loanwelcome .Paragraph {
            grid-column: 1;
            font-weight: 400;
            font-size: 4.86vw;
            width: 100%;
            line-height: 7.3vw;
            margin: 0px;
            padding: 7.3vw 0px 0px 0px;
            text-align: center;
            width: 100%;
          }
        }
      `}</style>
    </div>
  )
}
