import React, { useState, useEffect } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import COLORS from '../../resources/colors'

export default class Content extends React.Component {
  constructor(props) {
    super(props)

    this.id = props.id ? props.id : 'loancontent'

    this.myRef = React.createRef()

    this.state = {
      height: 0,
      visible: false,
    }

    this.src = this.props.src || ''
  }

  componentDidMount() {
    var height = 0

    if (this.myRef.current != null) {
      height = this.myRef.current.clientHeight
        ? this.myRef.current.clientHeight
        : 0
    }

    this.setState({
      height: height,
    })
  }

  visibilityChanged = visible => {
    this.setState({
      visible: visible,
    })
  }

  render() {
    let multiplier = this.props.imageMultiplier || 1.0

    return (
      <div id={this.id} ref={this.myRef}>
        <VisibilitySensor
          key={this.id}
          active={this.state.visible == false}
          partialVisibility={true}
          minTopValue={this.state.height / 2}
          onChange={this.visibilityChanged}
        >
          <div className="Content">
            <div className="LeftContent">
              <div className="Image">
                <img
                  className={this.state.visible ? 'Image Visible' : 'Image'}
                  src={this.src}
                />
              </div>
            </div>
            <div className="RightContent">
              <h1 className="Title">{this.props.title}</h1>
              <div className="Line"></div>
              <p className="Paragraph">{this.props.paragraph}</p>
            </div>
          </div>
        </VisibilitySensor>

        <style jsx="true">{`
    
                    #${this.id} .Content { 
                        background-color: ${this.props.backgroundColor};
                        padding: 6vw 15vw;
                        display: grid;
                        grid-template-columns: 25vw 1fr;
                        align-items: center;
                        grid-gap: 5vw;
                    }
    
                    #${this.id} .LeftContent { 
                        padding: 0px;
                        grid-row: 1;
                        grid-column: 1;
                    }
    
                    #${this.id} .RightContent { 
                        padding: 0px
                        grid-row: 1;
                        grid-column: 2;
                    }
    
                    #${this.id} .Image { 
                        width: ${25 * multiplier}vw;
                        height: ${25 * multiplier}vw;
                        margin: auto;
                        visibility: hidden;
                    }

                    #${this.id} .Image img { 
                        width: ${25 * multiplier}vw;
                        height: ${25 * multiplier}vw;
                        object-fit: contain;
                        display: block;
                    }

                    @keyframes enterBottomLeftToTopRight {
                        0% {
                          transform: translate(-10vw, 10vw);
                          visibility: hidden;
                          opacity: 0;
                        }
                        100% {
                          transform: translate(0, 0);
                          visibility: visible;
                          opacity: 1;
                        }
                    }

                    #${this.id} .Image.Visible { 
                        animation-duration: 0.75s;
                        animation-name: enterBottomLeftToTopRight; 
                        animation-fill-mode: both;
                        animation-delay: 0.0s;  
                    }
    
                    
    
                    #${this.id} .Title { 
                        font-weight: 700;
                        font-size: 3.3vw;
                        width: 100%; 
                        margin: 0px;
                        color: ${COLORS.white};
                        text-align: left;
                        padding: 0px;
                    }
    
                    #${this.id} .Line { 
                        width: 10vw;
                        background-color: ${COLORS.LightPurpleBlue};
                        height: 0.75vw;
                        border-radius: 0.75vw;
                        margin: 2.5vw 0px 0px 0px;   
                    }
    
                    #${this.id} .Paragraph { 
                        grid-column: 1;
                        font-weight: 400;
                        font-size: 1.5vw;
                        line-height: 2.25vw;
                        width: 100%;
                        margin: 0px;
                        padding: 2.5vw 0px 0px 0px;
                        color: ${COLORS.white};
                        text-align: left;
                        opacity: 0.8;
                    }
    
                    @media (max-width: 640px) { 
                        #${this.id} .Content { 
                            background-color: ${this.props.backgroundColor};
                            padding: 12vw 10vw;
                            display: grid;
                            grid-template-columns: 1fr;
                        }
        
                        #${this.id} .LeftContent { 
                            padding: 0px;
                            grid-row: 1;
                            grid-column: 1;
                        }
        
                        #${this.id} .RightContent { 
                            padding: 10vw 0px 0px 0px;
                            grid-row: 2;
                            grid-column: 1;
                        }
        
                        #${this.id} .Image { 
                            width: 57.5vw;
                            height: 57.5vw;
                            margin: auto;
                        }
        
                        #${this.id} .Image img { 
                            width: 57.5vw;
                            height: 57.5vw;
                            object-fit: contain;
                            display: block;
                        }
        
                        #${this.id} .Title { 
                            font-weight: 700;
                            font-size: 9.7vw;
                            width: 100%; 
                            margin: 0px;
                            text-align: left;
                            padding: 0px;
                        }
        
                        #${this.id} .Line { 
                            width: 20%;
                            height: 1.46vw;
                            border-radius: 1.46vw;
                            margin: 4.86vw auto 0px 0px;   
                        }
        
                        #${this.id} .Paragraph { 
                            grid-column: 1;
                            font-weight: 400;
                            font-size: 4.86vw;
                            width: 100%;
                            line-height: 7.3vw;
                            margin: 0px;
                            padding: 7.3vw 0px 0px 0px;
                            text-align: left;
                        }
                    }
      
                `}</style>
      </div>
    )
  }
}
